.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 2rem);
    max-width: 1000px;
    height: 90vh;
    background-color: #fff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
}

.CloseButton {
    position: absolute;
    right: 1.2em;
    top: 1em;
    z-index: 2;
    background: white;
    border-radius: 50%;
    padding: 0.25em;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.Content {
    flex: 1;
    overflow-y: auto;
    padding: 1.7em 2rem;
    font-size: 16px; /* Base font size for mobile */

    @media (min-width: 600px) {
        padding: 3em 4em;
    }

    @media (min-width: 1024px) {
        padding: 2em 6em;
        font-size: 16px;
    }

    /* Typography improvements */
    & h2 {
        margin: 2em 0 0.75em;
        font-size: 1.5em;
        line-height: 1.2;
        color: #2d2d2d;
    }

    & h2:first-child {
        margin-top: 0;
    }

    & p {
        margin: 0 0 1em;
        line-height: 1.6;
        color: #333;
    }

    & ul {
        margin: 0 0 1.5em;
        padding-left: 1.25em;
    }

    & li {
        margin-bottom: 0.5em;
        line-height: 1.6;
        padding-left: 0.5em;
    }

    & strong {
        font-weight: 600;
    }
}

.Footer {
    font-size: 11px; /* Smaller font size for footer */
    border-top: 1px solid #e0e0e0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 1em 1.5em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    background-color: #fafafa;
    color: #666; /* Slightly muted color for footer text */

    @media (min-width: 600px) {
        padding: 1em 3em;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 15px;
    }

    @media (min-width: 1024px) {
        padding: 1em 6em;
    }
}

.ButtonContainer {
    display: flex;
    gap: 0.75em;
    flex-shrink: 0; /* Prevent buttons from shrinking */
}
